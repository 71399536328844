export enum ELinkType {
  URL = 'url',
  EMAIL = 'email',
  PHONE = 'phone',
  SMS = 'sms',
  CONTACT = 'contact',
  FILE = 'file',
  BUSINESS_CARD = 'business_card',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  TIKTOK = 'tiktok',
  WECHAT = 'wechat',
  LOCATION = 'location',
  TEXT = 'text',
  APP = 'app',
  LINKEDIN = 'linkedin',
  SPOTIFY = 'spotify',
  WIFI = 'wifi',
  FORM = 'form',
  PAGE = 'page',
}

export interface IFile {
  name: string;
  size: number;
  type: string;
  lastModifiedDate: Date;
  lastModified: number;
  webkitRelativePath: string;
}

export interface ILinkConfigBase {
  type: ELinkType;
}

export interface ILinkConfigUrl extends ILinkConfigBase {
  type: ELinkType.URL;
  url: string;
}

export interface ILinkConfigEmail extends ILinkConfigBase {
  type: ELinkType.EMAIL;
  email: string;
  subject?: string;
  message?: string;
}

export interface ILinkConfigPhone extends ILinkConfigBase {
  type: ELinkType.PHONE;
  phone: string;
}

export interface ILinkConfigForm extends ILinkConfigBase {
  type: ELinkType.FORM;
  formId: string;
}

export interface ILinkConfigFile extends ILinkConfigBase {
  type: ELinkType.FILE;
  path: string;
  fileName: string;
  description?: string;
}

export interface ILinkConfigContactDetails {
  city?: string;
  country?: string;
  email?: string;
  fax?: string;
  mobile?: string;
  phone?: string;
  state?: string;
  street?: string;
  website?: string;
  zipcode?: string;
}

export interface ILinkConfigSocialProfiles {
  facebook?: string;
  twitter?: string;
  instagram?: string;
  weChat?: string;
  linkedIn?: string;
  spotify?: string;
  tiktok?: string;
  youtube?: string;
}

export interface ILinkConfigContact extends ILinkConfigBase {
  type: ELinkType.CONTACT | ELinkType.BUSINESS_CARD;
  description?: string;
  firstName: string;
  home?: ILinkConfigContactDetails;
  image?: string;
  lastName: string;
  organization?: string;
  position?: string;
  socialProfiles?: ILinkConfigSocialProfiles;
  template: string;
  templateColor: string;
  work?: ILinkConfigContactDetails;
}

export interface ILinkConfigSms extends ILinkConfigBase {
  type: ELinkType.SMS;
  message?: string;
  phone: string;
}

export interface ILinkConfigInstagram extends ILinkConfigBase {
  type: ELinkType.INSTAGRAM;
  profile: string;
}

export interface ILinkConfigFacebook extends ILinkConfigBase {
  type: ELinkType.FACEBOOK;
  subType: 'share' | 'profile';
  profileOrUrl: string;
}

export interface ILinkConfigTwitter extends ILinkConfigBase {
  type: ELinkType.TWITTER;
  subType: 'share' | 'profile';
  profileOrUrl: string;
}

export interface ILinkConfigYoutube extends ILinkConfigBase {
  type: ELinkType.YOUTUBE;
  url: string;
}

export interface ILinkConfigTikTok extends ILinkConfigBase {
  type: ELinkType.TIKTOK;
  profile: string;
}

export interface ILinkConfigWeChat extends ILinkConfigBase {
  type: ELinkType.WECHAT;
  profile: string;
}

export interface ILinkConfigLocation extends ILinkConfigBase {
  type: ELinkType.LOCATION;
  latitude: number;
  longitude: number;
}

export interface ILinkConfigText extends ILinkConfigBase {
  type: ELinkType.TEXT;
  text: string;
}

export interface ILinkConfigApp extends ILinkConfigBase {
  type: ELinkType.APP;
  name: string;
  company?: string;
  logo?: string | IFile;
  description?: string;
  ios: string;
  android: string;
}

export interface ILinkConfigLinkedIn extends ILinkConfigBase {
  type: ELinkType.LINKEDIN;
  profile: string;
}

export interface ILinkConfigSpotify extends ILinkConfigBase {
  type: ELinkType.SPOTIFY;
  url: string;
}

export interface ILinkConfigWifi extends ILinkConfigBase {
  type: ELinkType.WIFI;
  ssid: string;
  password: string;
  encryption: 'none' | 'wep' | 'wpa';
}

export interface ILinkConfigPage extends ILinkConfigBase {
  type: ELinkType.PAGE;
  pageId: string;
}

export type TLinkConnectQrConfigs =
  | ILinkConfigContact
  | ILinkConfigEmail
  | ILinkConfigFacebook
  | ILinkConfigForm
  | ILinkConfigFile
  | ILinkConfigInstagram
  | ILinkConfigLocation
  | ILinkConfigPhone
  | ILinkConfigSms
  | ILinkConfigTikTok
  | ILinkConfigTwitter
  | ILinkConfigUrl
  | ILinkConfigWeChat
  | ILinkConfigLinkedIn
  | ILinkConfigSpotify
  | ILinkConfigApp
  | ILinkConfigYoutube
  | ILinkConfigPage;

export type TLinkStaticQrConfigs =
  | ILinkConfigContact
  | ILinkConfigEmail
  | ILinkConfigFacebook
  | ILinkConfigInstagram
  | ILinkConfigLocation
  | ILinkConfigPhone
  | ILinkConfigSms
  | ILinkConfigTikTok
  | ILinkConfigTwitter
  | ILinkConfigUrl
  | ILinkConfigWeChat
  | ILinkConfigYoutube
  | ILinkConfigLinkedIn
  | ILinkConfigSpotify
  | ILinkConfigText
  | ILinkConfigWifi;

export type TLinkConnectQrPageConfigs =
  | ILinkConfigEmail
  | ILinkConfigForm
  | ILinkConfigPhone
  | ILinkConfigSms
  | ILinkConfigContact
  | ILinkConfigApp
  | ILinkConfigLocation
  | ILinkConfigFile
  | ILinkConfigPage;

export type TLinkConnectQrRedirectConfigs =
  | ILinkConfigFacebook
  | ILinkConfigInstagram
  | ILinkConfigTikTok
  | ILinkConfigTwitter
  | ILinkConfigUrl
  | ILinkConfigWeChat
  | ILinkConfigLinkedIn
  | ILinkConfigSpotify
  | ILinkConfigYoutube;
